const cycle = [
    {   
        id: 2,
        keyId: "cycle-2",
        image: '../images/herosprint0.jpeg',
        image1: '../images/herosprint.jpeg',
        image2: '../images/herosprint1.jpeg',
        image3: '../images/herosprint2.jpeg',
        image4: '../images/herosprint3.jpeg',
        price: 5999,
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: "Hero Sprint 26T SS Hybrid Bike with steel rim, power brakes, ",
        title: 'Hero Sprint',
        mrp: 6999,
        product_name: "Hero Sprint",
    },
    {   
        id: 3,
        keyId: "cycle-3",
        image: '../images/calburn.jpg',
        image1: '../images/calburn.jpeg',
        image2: '../images/calburn1.jpeg',
        image3: '../images/calburn2.jpeg',
        image4: '../images/calburn3.jpeg',
        price: 4749,
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: 'Cal Burn popstar bike with steel rim, without carrier, power brakes.  ',
        title: 'Cal Burn popstar bike',
        mrp: 6999,
        product_name: "cycle 2.0"

    },
    {   
        id: 4,
        keyId: "cycle-4",
        image: '../images/hainken.jpeg',
        image1: '../images/hainken.jpeg',
        image2: '../images/hainken.jpeg',
        image3: '../images/haiken2.jpeg',
        image4: '../images/haiken3.jpeg',
        price: 4449,
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: 'Hianken Revolt bicycle, mountain bike (Single Speed, Black,Green,Yellow). without carrier, power brakes, thin tyres. ',
        title: 'Hianken Revolt bicycle',
        mrp: 5000,
        product_name: "Hianken Revolt"
    },
    {   
        id: 5,
        keyId: "cycle-5",
        image: '../images/herclus0.jpeg',
        image1: '../images/herclus.jpeg',
        image2: '../images/herclus1.jpeg',
        image3: '../images/herclus2.jpeg',
        image4: '../images/herclus3.jpeg',
        price: 5249,
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: 'Herculus Street Rider RF 26 T Roadster Cycle 26 T Road Cycle (Single Speed, Black). with carrier, power brakes, thin tyres. ',
        title: 'Herculus Street Rider',
        mrp: 6500,
        product_name: "Herculus Street Rider"
    },
    {   
        id: 6,
        keyId: "cycle-6",
        image2: '../images/striker1.jpeg',
        image3: '../images/striker2.jpeg',
        image1: '../images/striker.jpeg',
        image: '../images/striker0.jpeg',
        image4: '../images/striker3.jpeg',
        price: 4649,
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: 'Gang Striker Non-Suspension 26 T Mountain/Hardtail Cycle (Single Speed, Black, Red), with power brake, without carrier, and with steel rim ',
        title: 'Gang Striker bike (Most Preffered)',
        mrp: 5299,
        product_name: "Gang Striker bike"
    },
    {   
        id: 7,
        keyId: "cycle-7",
        image: '../images/fido0.jpeg',
        image1: '../images/fido.jpeg',
        image2: '../images/fido3.jpeg',
        image3: '../images/fido2.jpeg',
        image4: '../images/fido3.jpeg',
        price: 3649,
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: 'Om-star Fido (preffered by girls Mostly) with carrier, comes with steel mugguard (can be change with plastic mugguard without extra charge) ',
        title: 'Om-star Fido',
        mrp: 4100,
        product_name: "Om-star Fido",

    },
    {   
        id: 1,
        keyId: "cycle-1",
        image: '../images/avon0.jpeg',
        image1: '../images/avon2.jpeg',
        image2: '../images/avon3.jpeg',
        image3: '../images/avon4.jpeg',
        image4: '../images/avon.jpeg',
        price: 5750,
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days ',
        desc: 'Avon Gamer APEX IBC 26T Mountain/Hardtail Cycle (Single Speed, Black,Red). Aluminium rim, without carrier, power breaks ',
        title: 'Avon Gamer APEX IBC 26T',
        mrp: 6575,
        product_name: "Avon Gamer"

    },
    {   
        id: 8,
        keyId: "cycle-8",
        image: '../images/altima0.jpeg',
        image1: '../images/altima.jpeg',
        image2: '../images/altima1.jpeg',
        image3: '../images/altima2.jpeg',
        image4: '../images/altima.jpeg',
        price: 4350,
        title: 'Om-star Ultima',
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: 'Om-star Ultima comes with power brake, carrier, and steel rim',
        mrp: 5000,
        product_name: "Om-star Ultima "

    },
    {   
        id: 9,
        keyId: "cycle-9",
        image: '../images/gangstriker.jpeg',
        image1: '../images/gangstriker.jpeg',
        image2: '../images/gangstriker.jpeg',
        image3: '../images/gangstriker.jpeg',
        image4: '../images/gangstriker.jpeg',
        price: 7449,
        title: 'Gang Striker With gear',
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: 'Gang Striker comes with front and back gear and disk brake, front shocker',
        mrp: 8299,
        product_name: "Om-star Ultima "

    },
    {   
        id: 10,
        keyId: "cycle-10",
        image: '../images/rainbow.jpeg',
        image1: '../images/rainbow.jpeg',
        image2: '../images/rainbow.jpeg',
        image3: '../images/rainbow.jpeg',
        image4: '../images/rainbow.jpeg',
        price: 5999,
        title: 'Rainbow Power Bike',
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: 'Rainbow bike come with front shocker, front and back disk brake, aluminium rim, fat handle with bearing, carrier',
        mrp: 6589,
        product_name: "Rainbow bike"

    },
    {   
        id: 11,
        keyId: "cycle-11",
        image: '../images/modern.jpeg',
        image1: '../images/modern.jpeg',
        image2: '../images/modern.jpeg',
        image3: '../images/modern.jpeg',
        image4: '../images/modern.jpeg',
        price: 6849,
        title: 'Modern Feast Mountain bike',
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: 'Modern feast bike come with front and back shocker, front and back disk brake, aluminium rim, carrier',
        mrp: 7100,
        product_name: "Modern feast bike",
    },
    {   
        id: 12,
        keyId: "cycle-12",
        image: '../images/popstarcarrier.jpeg',
        image1: '../images/popstarcarrier.jpeg',
        image2: '../images/popstarcarrier.jpeg',
        image3: '../images/popstarcarrier.jpeg',
        image4: '../images/popstarcarrier.jpeg',
        price: 4749,
        title: 'Popstar Mountian Bike',
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: 'Popstar comes with power break, carrier, steel rim',
        mrp: 5100,
        product_name: "popstar with carrier",
    },
    {   
        id: 13,
        keyId: "cycle-13",
        image: '../images/momstar.jpeg',
        image1: '../images/momstar.jpeg',
        image2: '../images/momstar.jpeg',
        image3: '../images/momstar.jpeg',
        image4: '../images/momstar.jpeg',
        price: 4749,
        title: 'Momstar Bike',
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: 'Momstar comes with power break, carrier, steel rim, heavy frame and fat tires',
        mrp: 5100,
        product_name: "Momstar",
    },
    {   
        id: 14,
        keyId: "cycle-14",
        image: '../images/vocue.jpeg',
        image1: '../images/vocue.jpeg',
        image2: '../images/vocue.jpeg',
        image3: '../images/vocue.jpeg',
        image4: '../images/vocue.jpeg',
        price: 4759,
        title: 'Vocue power bike',
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: 'vocue bike comes with back shocker, fat tyre, power brake, carrier, fat and heavy frame',
        mrp: 4900,
        product_name: "vocue cycle",
    },
    {   
        id: 15,
        keyId: "cycle-15",
        image: '../images/popstarshocker.jpeg',
        image1: '../images/popstarshocker.jpeg',
        image2: '../images/popstarshocker.jpeg',
        image3: '../images/popstarshocker.jpeg',
        image4: '../images/popstarshocker.jpeg',
        price: 4759,
        title: 'Popstar Gladiator',
        fixdesc: 'Comes with guarantee and waranty bill and also first 3 services are free. Replacement time: 10 days',
        desc: 'Popstar comes with power brake, back and front shocker, carrier, steel rim, light weight, fat tyre, heavy duty',
        mrp: 4900,
        product_name: "vocue cycle",
    },
]
export default cycle;