const Homecontent = [
    
    {
        id: 1,
        image: 'images/cycle.jpg',
        content: 'Get the best Cycle At the best affordable price in Jaipur and get it delivered in same day at your college gate.',
        title: 'Cycles',
        name: 'cycle',
        headline: 'Ride your college years with MintraGo.',
        button: 'View Cycle',
    },
    {
        id: 2,
        image: 'images/dress.png',
        content: 'Book your Mechanical dress for Mechanical Lab only for Rs 20 and book your lab coat for the Chemical Lab for only Rs 10',
        title: 'Lab Dress',
        name: 'dressforrent',
        headline: 'Aab Pay karo us din jis din jao lab',
        button: 'Proceed',
    },
    
]
export default Homecontent